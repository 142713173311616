import { jsx as _jsx } from "react/jsx-runtime";
import withPercentageIncomeBadge from '@shared/ui/badges/withPercentageIncomeBadge';
import { Body } from '@components/styled/Typography';
import QuestionMarkTooltip from '@shared/ui/tooltips/QuestionMarkTooltip';
const IncomeBadge = withPercentageIncomeBadge(Body);
const RenderIncomeBadge = ({ cellParams }) => (_jsx(IncomeBadge, { percentageIncome: cellParams.value }));
const RenderIncomeBadgeTinted = ({ cellParams }) => (_jsx(IncomeBadge, { badgeVariant: 'tinted', percentageIncome: cellParams.value }));
const RenderIncomeBadgePlain = ({ cellParams }) => (_jsx(IncomeBadge, { badgeVariant: 'plain', percentageIncome: cellParams.value }));
const RenderIncomeBadgeTintedWithTooltip = (tooltipText) => ({ cellParams }) => (_jsx(IncomeBadge, { badgeVariant: 'tinted', percentageIncome: cellParams.value, badgeEndAdornment: (_jsx(QuestionMarkTooltip, { title: tooltipText, placement: 'top', 
        // TODO: check it, feels like not an obvious solution fo color inheritance
        iconColor: 'currentColor', arrow: true })) }));
const RenderIncomeBadge30D = ({ cellParams }) => (_jsx(IncomeBadge, { badgeVariant: cellParams.isSelected ? 'filled' : 'tinted', percentageIncome: cellParams.value }));
export { RenderIncomeBadge, RenderIncomeBadge30D, RenderIncomeBadgeTinted, RenderIncomeBadgeTintedWithTooltip, RenderIncomeBadgePlain, };
