import { jsx as _jsx } from "react/jsx-runtime";
import useAppNavigation from '@utils/hooks/useAppNavigation';
import ProfileThumbnail from '@shared/ui/display/ProfileThumbnail';
import { IsDefined } from '@utils/js-ts';
const RenderProfileThumbnail = ({ cellParams }) => {
    const { navigate, routes } = useAppNavigation();
    // TODO: typecheck
    const profile = cellParams.value;
    const navigateToProfile = () => {
        navigate(routes.PROFILE, { params: { nickname: String(profile.username) } });
    };
    if (!IsDefined(profile)) {
        // TODO: handle
        return _jsx("strong", { children: "-" });
    }
    return (_jsx(ProfileThumbnail, { userId: String(profile.id), avatarImgUrl: profile.profilePicture ? profile.profilePicture.avatar : '', username: profile.displayName, nickname: profile.username, onClick: navigateToProfile }));
};
export default RenderProfileThumbnail;
