import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import ProfileActionButton from '@features/profile/profile-follow';
import ProfileMoreActionsDropdown from '@shared/ui/menus/ProfileMoreActionsDropdown';
const ProfileActionButtonContainer = styled.div(() => ({
    maxWidth: 92,
    width: '100%',
}));
const ProfileActionButtonStyled = styled(ProfileActionButton)(() => ({
    width: '100%',
    // TODO: discuss it with designer(that`s what we shouldn`t do)
    '& .MuiTypography-root': {
        fontSize: 12,
    },
}));
const ActionButtonContainer = styled.div(props => ({
    display: 'flex',
    justifyContent: 'end',
    width: '100%',
    overflow: 'unset',
    gap: props.theme.spacing_sizes.xs * 2.5,
    maxHeight: 32,
    '& .MuiPopper-root': {
        zIndex: 1,
        minWidth: 240,
    },
    '& .MuiList-root': {
        padding: `${props.theme.spacing_sizes.xs * 0.5}px 0`,
    },
}));
const RenderProfileActionButton = ({ cellParams }) => {
    const profile = cellParams.rowData;
    const actionType = !profile.mutualSubscription ? 'follow' : 'unfollow';
    return (_jsxs(ActionButtonContainer, { children: [_jsx(ProfileActionButtonContainer, { children: _jsx(ProfileActionButtonStyled, { profileId: profile.id, actionType: actionType }) }), _jsx(ProfileMoreActionsDropdown, { profile: profile, size: 'small', color: 'secondary', variant: 'tinted' })] }));
};
export default RenderProfileActionButton;
